@font-face {
  font-family: 'NeusaBlack';  /* Separate family name for Black weight */
  src: url('./Neusa-Black.otf') format('opentype');
  font-weight: normal;  /* Reset to normal since we're using a separate family */
  font-style: normal;
}

@font-face {
  font-family: 'Neusa';
  src: url('./Neusa-Light.otf') format('opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Neusa';
  src: url('./Neusa-Regular.otf') format('opentype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Neusa';
  src: url('./Neusa-Medium.otf') format('opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Neusa';
  src: url('./Neusa-DemiBold.otf') format('opentype');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Neusa';
  src: url('./Neusa-Bold.otf') format('opentype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Neusa';
  src: url('./Neusa-ExtraBold.otf') format('opentype');
  font-weight: 800;
  font-style: normal;
}
