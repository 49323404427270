html {
  scroll-behavior: smooth;
  scroll-padding-top: 91px; /* Height of navbar */
}

body {
  margin: 0;
  font-family: 'Helvetica Neue', Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.slick-dots {
  bottom: 25px;
}

.slick-dots li button:before {
  color: #fff;
  font-size: 12px;
  opacity: 0.75;
}

.slick-dots li.slick-active button:before {
  color: #fff;
  opacity: 1;
}
